.project-inner {
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
}
.project-inner-viewmore {
  border-radius: 20px 20px 20px 20px;
  padding: 20px;
}
.search-bar {
  /* border-bottom: 1px solid #CECECE; */
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.project-inner h2 {
  font-size: 20px;
  font-weight: 600;
  color: #1e1e1e;
}

.project-heading h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1e1e1e;
}

.search-bar form {
  position: relative;
  width: 100%;
}

.project-col {
  height: 460px;
  overflow-y: auto;
}

.search-bar input {
  border-color: #cecece;
  color: #1e1e1e;
}
.search-bar form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.sprint-loop + .sprint-loop {
  margin-top: 15px;
}

.project-sprint-section {
  width: -webkit-fill-available;
}
.done-count {
  color: #362460;
  font-weight: 600;
}

.total-progress {
  position: relative;
  height: 20px;
  background: #cecece;
  border-radius: 5px;
  overflow: hidden;
}

.current-progress {
  background: #57a5fb;
  display: block;
  width: 0%;
  border-radius: 5px;
  height: 100%;
}

.prodect-detail {
  border-radius: 10px;
  background: rgba(0, 118, 248, 0.05);
  padding: 20px;
  width: calc(100% - 30px);
}

.task-progress {
  position: relative;
  height: 10px;
  background: #cecece;
  border-radius: 10px;
  overflow: hidden;
}

.current-task-progress {
  display: block;
  width: 0%;
  border-radius: 10px;
  height: 100%;
  background: #57a5fb;
}

.project-label {
  line-height: 12px;
}

.project-label > li:not(:last-child) {
  margin-right: 10px;
  padding-right: 10px;
  color: #1e1e1e;
  border-right: 1px solid #1e1e1e;
}

.view-names h4 {
  color: #0076f8;
  font-size: 16px;
}

.view-names button {
  color: #1e1e1e;
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  font-weight: 600;
}

.project-loop {
  padding: 0 20px;
}

.project-col .project-loop:not(:last-child) {
  margin-bottom: 1rem;
}

.find-project-btn button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  margin-left: 15px;
}

.find-project-btn button + button {
  margin-left: 15px;
}

.project-col::-webkit-scrollbar {
  width: 5px;
}

.project-col::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.project-col::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

@media screen and (max-width: 760px) {

  .project-inner {
    border-radius: 20px 20px 20px 20px;
  }

  .prodect-detail {
    width: -webkit-fill-available;
  }

  .progress-stacked > .progress > .progress-bar {
    font-size: 0;
  }
}

@media screen and (max-width: 575.98px) {
  .project-inner {
    padding: 10px;
  }
}


.limit-name-progress {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}
