.assigned-tasks {
  border-radius: 20px 20px 0px 0px;
  background: #dbe7ff;
  padding: 20px;
  /* height: 100%; */
  border-top: 8px solid #57a5fb;
  border-right: 2px solid #57a5fb;
  border-bottom: 2px solid #57A5FB;
  border-left: 2px solid #57a5fb;
}

.assigned-tasks-viewmore {
  border-radius: 20px 20px 20px 20px;
  background: #dbe7ff;
  padding: 20px;
  /* height: 100%; */
  border-top: 8px solid #57a5fb;
  border-right: 2px solid #57a5fb;
  border-bottom: 2px solid #57a5fb;
  border-left: 2px solid #57a5fb;
}

.assigned-tasks h2 {
  font-size: 20px;
  font-weight: 600;
  /* color: #1E1E1E; */
}

.task-heading {
  /* border-bottom: 1px solid #A899CC; */
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.viewmore-line {
  border-bottom: 1px solid #a899cc;
}

.task-heading button+button {
  margin-left: 15px;
}

/* .task-heading .add-icon {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
} */

.task-heading button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

.task-heading button+button {
  margin-left: 15px;
}

.tasks-label h3 {
  font-size: 16px;
  font-weight: 600;
  color: #57a5fb;
}

.tasks-data {
  height: 172px;
  overflow-y: auto;
}

.tasks-data::-webkit-scrollbar {
  width: 5px;
}

.tasks-data::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.tasks-data::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.tasks-data-viewmore {
  height: 370px;
  overflow-y: auto;
}

.tasks-data-viewmore::-webkit-scrollbar {
  width: 5px;
}

.tasks-data-viewmore::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.tasks-data-viewmore::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.tasks-data p {
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
}

.tasks-data-viewmore p {
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
}

.tasks-data .row:not(.task-pending) p {
  color: #1e1e1e;
}

.status-completed p {
  color: #289659 !important;
}

.task-action-assigned {
  border: 0px;
  background: #7257ae !important;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  float: right;
  font-weight: 500;
  padding: 3px 15px !important;
}

.task-action-assigned img {
  margin-right: 5px;
}

.task-action {
  border: 0px;
  padding: 0px;
  background: transparent;
  color: #1e1e1e;
}

.tasks-data label {
  font-size: 16px;
  font-weight: 600;
  display: block;
}

/* .task-pending {
    background: #F13A3A;
} */

.tasks-data .row:not(.task-pending) p {
  /* background: #F13A3A; */
  color: #1e1e1e;
}

.tasks-data .task-pending :not(.no-color) label+* {
  background: #F13A3A;
}

.tasks-data-viewmore .row:not(.task-pending) p {
  color: #1e1e1e;
}

.tasks-data-viewmore .task-pending :not(.no-color) label+* {
  background: #F13A3A;
}
.tasks-data-viewmore .row:not(.task-pending) label {
  display: none;
}
.tasks-data-viewmore .task-pending label{
  display: none;
}

.tasks-data .row:not(:last-child) {
  margin-bottom: 5px;
}

.tasks-data-viewmore .row:not(:last-child) {
  margin-bottom: 5px;
}

.mark-complete {
  border-radius: 4px;
  padding: 0px 6px;
  color: #fff;
  background: #289659;
  font-weight: 600;
  outline: none;
  border: 0px;
  width: 34px;
  height: 31px;
  margin-right: 5px;
}

.mark-complete-pencil {
  border-radius: 4px;
  padding: 0px 6px;
  color: #fff;
  background: #7D9CFF;
  font-weight: 600;
  outline: none;
  border: 0px;
  width: 34px;
  height: 31px;
  margin-right: 5px;
}

.mark-complete-undo {
  border-radius: 4px;
  padding: 0px 6px;
  color: #fff;
  background: #F13A3A;
  font-weight: 600;
  outline: none;
  border: 0px;
  width: 34px;
  height: 31px;
  margin-right: 5px;
}

.hash-no {
  padding-left: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media screen and (min-width: 922px) {
  .tasks-data label {
    display: none;
  }
}

@media screen and (max-width: 760px) {

  .assigned-tasks {
    height: 366px;
    border-radius: 20px 20px 20px 20px;
  }

  .assigned-tasks>.task-heading h2 {
    font-size: 15px;
  }

  .tasks-data {
    height: 270px;
  }

  .task-view div>p {
    font-size: 15px;
    /* color: #1e1e1e; */
    font-weight: 400;
  }
  .tasks-data label {
    display: none;
  }

  .edit-task-action:first-child>img {
    width: 20px;
  }

  .edit-task-action:last-child>img {
    width: 15px;
    margin-right: 15px;
  }

  .tasks-data .row:not(:last-child) {
    margin-bottom: 15px;

  }

  .tasks-data .row {
    margin-right: 10px;
  }

  .tasks-data div>div:first-child {
    display: none;
  }

  .tasks-data>div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .task-data {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .tasks-data-viewmore div>div:first-child {
    display: none;
  }

  .tasks-data-viewmore>div {
    /* background: #eff6ff;
    border-radius: 20px;
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-top: 4px solid #57a5fb;
    border-right: 2px solid #57a5fb;
    border-left: 2px solid #57a5fb;
    border-bottom: 2px solid #57a5fb; */
    border-bottom: 1px solid #7B838A;
  }



  .tasks-data-viewmore {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .tasks-data-viewmore .row:not(:last-child) {
    margin-bottom: 15px;

  }

  .tasks-data-viewmore .row {
    margin-right: 15px;
  }

  .task-data>button:nth-child(1) {
    background: white;
    color: black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 40px;
    height: 30px;
    border-bottom: 2px solid #289659;
  }

  .task-data>button:nth-child(1)>img {
    color: white;
    padding-right: 0px;
  }

  /* .task-data > button:nth-child(1)::after {
    content: 'Edit';
  } */

  /* .task-data>button:nth-child(2) {
    background: white;
    color: black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 90px;
    height: 30px;
    border-bottom: 2px solid #000;
  }

  .task-data>button:nth-child(2)::after {
    content: 'Edit';
  } */


  .assigned-tasks-mobile {
    margin-bottom: 1.5rem;
    width: -webkit-fill-available;
  }

  .task-view>div:nth-child(-n+7), .task-view>div.task-pending {
    background-color: #fff;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
  }
  .task-view>div.task-pending {
    background-color: #F13A3A;
  }

  .tasks-data .row:not(.task-pending) p {
    color: #1e1e1e;
  }
  
  .tasks-data .task-pending :not(.no-color) label+* {
    background: #FFF;
  }
  .task-view div>p {
    color: #1e1e1e;
  }

  .task-due {
    background-color: #F13A3A !important;
  }

  .task-due::before{
    color: #FFF !important;
  }
  .task-due p{
    background-color: #F13A3A !important;
    color: #FFF !important;
  }

  /* .task-view>div.task-pending:nth-child(5) {
    background-color: #F13A3A;;
  } */

  /* .task-view>div.task-pending:nth-child(-n+7) {
    background-color: #F13A3A;
  } */

  /* .tasks-data .row:not(.task-pending) p {
    color: #1e1e1e;
  }
  
  .tasks-data .task-pending :not(.no-color) label+* {
    background: #F13A3A;
  } */


  .task-view>div:nth-child(2)>p {
    /* font-size: 20px; */
    font-weight: 600;
  }

  .task-view>div:nth-child(2)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 91px;
    color: #7B838A;
  }

  .task-view>div:nth-child(2) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(2)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 88px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(2) {
    display: flex;
  }

  .task-view>div:nth-child(3)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 63px;
    color: #7B838A;
  }

  .task-view>div:nth-child(3) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(3)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 60px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(3) {
    display: flex;
  }

  .task-view>div:nth-child(4)::before {
    content: 'Created By:';
    font-weight: 600;
    font-size: 15px;
    min-width: 77px;
    color: #7B838A;
  }

  .task-pending>div:before{
    max-width: 140px;
    width: 100%;
  }

  .task-pending>div{
    display: flex;
  }
  .task-view>div:before{
    max-width: 140px;
    width: 100%;
  }

  .task-view>div{
    display: flex;
  }

  .task-view>div:nth-child(4) {
    display: flex;
  }

  .task-view>div:nth-child(5)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 77px;
    color: #7B838A;
  }

  .task-view>div:nth-child(5) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(5)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 74px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(5) {
    display: flex;
  }

  .task-view>div:nth-child(6)::before {
    content: 'Remaining Days: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 129px;
    color: #7B838A;
  }

  .task-view>div:nth-child(5) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(6)::before {
    content: 'Remaining Days:';
    font-weight: 600;
    font-size: 15px;
    min-width: 125px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(6) {
    display: flex;
  }

  .task-view>div:nth-child(7)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 56px;
    color: #7B838A;
  }

  .task-view>div:nth-child(7) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(7)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 55px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(7) {
    display: flex;
  }

  .tasks-label {
    display: none !important;
  }

  /* .col-lg-1 {
    width: 35%;
  } */
}

@media screen and (min-width: 760px) and (max-width: 1024px) {
  /* .tasks-label>div {
    display: grid;
    grid-template-columns: 0.4fr repeat(6, 1.2fr);
  }

  .tasks-data>div {
    display: grid;
    grid-template-columns: 0.4fr repeat(6, 1.2fr);
  } */

  .assigned-tasks {
    height: 366px;
    border-radius: 20px 20px 20px 20px;
  }

  .assigned-tasks>.task-heading h2 {
    font-size: 15px;
  }

  .tasks-data {
    height: 270px;
  }

  .task-view div>p {
    font-size: 15px;
    /* color: #1e1e1e; */
    font-weight: 400;
  }
  .tasks-data label {
    display: none;
  }

  .edit-task-action:first-child>img {
    width: 20px;
  }

  .edit-task-action:last-child>img {
    width: 15px;
    margin-right: 15px;
  }

  .tasks-data .row:not(:last-child) {
    margin-bottom: 15px;

  }

  .tasks-data .row {
    margin-right: 10px;
  }

  .tasks-data div>div:first-child {
    display: none;
  }

  .tasks-data>div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .task-data {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .tasks-data-viewmore div>div:first-child {
    display: none;
  }

  .tasks-data-viewmore>div {
    /* background: #eff6ff;
    border-radius: 20px;
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-top: 4px solid #57a5fb;
    border-right: 2px solid #57a5fb;
    border-left: 2px solid #57a5fb;
    border-bottom: 2px solid #57a5fb; */
    border-bottom: 1px solid #7B838A;
  }



  .tasks-data-viewmore {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .tasks-data-viewmore .row:not(:last-child) {
    margin-bottom: 15px;

  }

  .tasks-data-viewmore .row {
    margin-right: 15px;
  }

  .task-data>button:nth-child(1) {
    background: white;
    color: black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 40px;
    height: 30px;
    border-bottom: 2px solid #289659;
  }

  .task-data>button:nth-child(1)>img {
    color: white;
    padding-right: 0px;
  }

  /* .task-data > button:nth-child(1)::after {
    content: 'Edit';
  } */

  /* .task-data>button:nth-child(2) {
    background: white;
    color: black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 90px;
    height: 30px;
    border-bottom: 2px solid #000;
  }

  .task-data>button:nth-child(2)::after {
    content: 'Edit';
  } */


  .assigned-tasks-mobile {
    margin-bottom: 1.5rem;
    width: -webkit-fill-available;
  }

  .task-view>div:nth-child(-n+7), .task-view>div.task-pending {
    background-color: #fff;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
  }
  .task-view>div.task-pending {
    background-color: #F13A3A;
  }

  .tasks-data .row:not(.task-pending) p {
    color: #1e1e1e;
  }
  
  .tasks-data .task-pending :not(.no-color) label+* {
    background: #FFF;
  }
  .task-view div>p {
    color: #1e1e1e;
  }

  .task-due {
    background-color: #F13A3A !important;
  }

  .task-due::before{
    color: #FFF !important;
  }
  .task-due p{
    background-color: #F13A3A !important;
    color: #FFF !important;
  }
  .task-view>div:nth-child(2)>p {
    /* font-size: 20px; */
    font-weight: 600;
  }

  .task-view>div:nth-child(2)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 91px;
    color: #7B838A;
  }

  .task-view>div:nth-child(2) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(2)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 88px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(2) {
    display: flex;
  }

  .task-view>div:nth-child(3)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 63px;
    color: #7B838A;
  }

  .task-view>div:nth-child(3) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(3)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 60px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(3) {
    display: flex;
  }

  .task-view>div:nth-child(4)::before {
    content: 'Created By:';
    font-weight: 600;
    font-size: 15px;
    min-width: 77px;
    color: #7B838A;
  }

  .task-pending>div:before{
    max-width: 140px;
    width: 100%;
  }

  .task-pending>div{
    display: flex;
  }
  .task-view>div:before{
    max-width: 140px;
    width: 100%;
  }

  .task-view>div{
    display: flex;
  }

  .task-view>div:nth-child(4) {
    display: flex;
  }

  .task-view>div:nth-child(5)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 77px;
    color: #7B838A;
  }

  .task-view>div:nth-child(5) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(5)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 74px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(5) {
    display: flex;
  }

  .task-view>div:nth-child(6)::before {
    content: 'Remaining Days: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 129px;
    color: #7B838A;
  }

  .task-view>div:nth-child(5) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(6)::before {
    content: 'Remaining Days:';
    font-weight: 600;
    font-size: 15px;
    min-width: 125px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(6) {
    display: flex;
  }

  .task-view>div:nth-child(7)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 56px;
    color: #7B838A;
  }

  .task-view>div:nth-child(7) {
    display: flex;
  }

  .tasks-data-viewmore .task-view>div:nth-child(7)::before {
    content: 'Status: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 55px;
    color: #7B838A;
  }

  .tasks-data-viewmore .task-view>div:nth-child(7) {
    display: flex;
  }

  .tasks-label {
    display: none !important;
  }
  .task-pending>div:before{
    max-width: 360px;
    width: 100%;
  }

  .task-pending>div{
    display: flex;
  }
  .task-view>div:before{
    max-width: 360px;
    width: 100%;
  }

  .task-view>div{
    display: flex;
  }



}

@media screen and (max-width: 575.98px) {
    .assigned-tasks{
      padding: 10px;
    }
}

.col-lg-2 p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.limit-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

