body {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.7;
    color: #1E1E1E;
}

img {
    max-width: 100%;
    height: auto;
}

.page-header {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
}

.border-btn {
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    display: inline-block;
    padding: 6px 20px;
    text-decoration: none;
    min-width: auto;
}

.border-btn.blue-border {
    border: 1px solid #57a5fb;
    color: #57a5fb;
}

.filled-btn {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    display: inline-block;
    padding: 6px 20px;
    text-decoration: none;
}

.filled-btn.blue-filled {
    background: #57a5fb;
    border: 1px solid #57a5fb;
}


.main-menu {
    display: block;
}

.main-menu ul {}

.main-menu ul li a {
    display: block;
    color: #362460;
    line-height: 18px;
    border-bottom: 1px solid transparent;
    font-weight: 500;
    text-decoration: none;
}

.main-menu ul li a:hover {
    border-color: #362460;
}

.main-menu ul li a.open {
    color: #362460;
    font-weight: 600;
}

.top-btn a {
    font-size: 18px;
}

.filled-btn.navy-filled {
    background: #362460;
    border: 1px solid #362460;
}

.main-video {
    position: relative;
}

.play-btn {
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 0;
    outline: none;
}

.video-item {
    width: 100%;
    object-fit: cover;
    height: 100%;
    display: block;
}

.top-banner {
    background: url('../../assests/icons/top-bg.png');
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
}

.top-banner h1 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #362460;
}

.top-banner p {
    color: #362460;
    font-size: 24px;
    margin-bottom: 15px;
}

.filled-btn.navy-filled {
    background: #362460;
    border: 1px solid #362460;
}

.about-section h2 {
    font-size: 32px;
    font-weight: 600;
    color: #1E1E1E;
    margin-bottom: 20px;
}

.about-section h3 {
    font-size: 24px;
    color: #1E1E1E;
    margin-bottom: 15px;
}

.about-section h3 span {
    color: #57A5FB;
}

.about-section ul {}

.about-section ul li {
    font-size: 18px;
    font-weight: 500;
    position: relative;
    padding-left: 35px;
}

.about-section ul li:not(:last-child) {
    margin-bottom: 10px;
}

.about-section ul li:before {
    content: "";
    width: 26px;
    height: 26px;
    background: #57a5fb;
    position: absolute;
    border-radius: 100%;
    top: 2px;
    left: 0px;
    background-image: url(../../assests/icons/check.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
}

.hero-banner {
    background: url('../../assests/images/hero-image.png');
    padding: 90px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: relative;
}

.hero-banner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #000;
    opacity: 0.3;
    height: 100%;
}


.banner-caption {
    max-width: 630px;
    width: 100%;
    position: relative;
}

.banner-caption h3 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.banner-caption h2 {
    font-weight: 700;
    color: #fff;
    line-height: 55px;
    font-size: 40px;
    margin-bottom: 20px;
}

.banner-caption p {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
}

.banner-caption a {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    background: #5EA6F8;
    display: inline-block;
    text-decoration: none;
    padding: 8px 25px;
    border-radius: 10px;
}


.project-platform {
    padding: 75px 0px;
    background: #E6F2FE;
}

.project-platform h2 {
    font-size: 40px;
    font-weight: 700;
    color: #362460;
}

.project-platform h2+p {
    margin-bottom: 40px;
    font-size: 20px;
    color: #2B3857;
}

.project-platform .project-imgg {
    height: 65px;
}

.project-platform .row img {}

.project-platform .row p {
    font-size: 20px;
    margin-bottom: 0px;
    color: #2B3857;
    font-weight: 600;
    line-height: 30px;
    margin-top: 10px;
}

.section-tabs {
    margin-top: 75px;
}

.section-tabs .nav-pills {
    justify-content: center;
}

.section-tabs .nav-pills li {
    border-bottom: 2px solid #C1C9D8;
}

.section-tabs .nav-pills li button {
    font-size: 16px;
    color: #657694;
    font-weight: 600;
    background: transparent !important;
    padding: 5px 20px !important;
    position: relative;
}

.section-tabs .nav-pills li img {
    margin-right: 8px;
}

.section-tabs .nav-pills li button:after {
    content: "";
    height: 4px;
    width: 100%;
    position: absolute;
    background: transparent;
    bottom: -4px;
    left: 0px;
}

.section-tabs .nav-pills li .nav-link.active {
    color: #181F38 !important;
}

.section-tabs .nav-pills li .nav-link.active:after {
    background: #5EA6F8;
}

.section-tabs .tab-content {}

.section-tabs .tab-content h3 {
    font-size: 16px;
    color: #657694;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.section-tabs .tab-content h2 {
    color: #362460;
    font-weight: 700;
    font-size: 34px;
}

.section-tabs .tab-content p {
    font-size: 18px;
    color: #2B3857;
}

.see-all a {
    font-size: 18px;
}

.see-all {
    margin-top: 20px;
}

.see-all a {
    font-size: 20px;
    font-weight: 500;
    color: #5EA6F8;
    display: inline-block;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    line-height: 25px;
}

.see-all a:hover {
    border-color: #5EA6F8;
}

.see-all a img {
    margin-left: 5px;
}



.trust-section {
    padding: 75px 0px 120px 0px !important;
}

.trust-section h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #362460;
}

.trust-text {
    border-radius: 20px;
    padding: 20px;
}


.trust-text p {
    font-size: 24px;
    line-height: 36px;
    color: #475668;
    margin-bottom: 20px;
}

.trust-wala h4 {
    font-size: 16px;
    font-weight: 600;
    color: #001F3F;
}

.trust-wala h5 {
    font-size: 16px;
    font-weight: 400;
    color: #475668;
}

.our-numbers {
    padding: 75px 0px;
    background: #9AC9FD;
}

.our-numbers h2 {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 35px;
}

.total-num {
    margin-bottom: 5px;
    font-size: 56px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
}

.our-numbers p {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
}


.our-numbers .row>*:not(:last-child) {
    border-right: 2px solid #fff;
    padding-top: 15px;
    padding-bottom: 15px;
}


.support-section h3 {
    font-size: 36px;
    font-weight: 600;
    color: #362460;
    margin-top: 10px;
    margin-bottom: 15px;
}

.support-section ul li:not(:last-child) {
    margin-bottom: 10px;
}

.support-section ul li {
    font-size: 18px;
    position: relative;
    padding-left: 35px;
    color: #111;
}

.support-section ul li:before {
    content: "";
    width: 26px;
    height: 26px;
    background: transparent;
    position: absolute;
    border-radius: 100%;
    top: 2px;
    left: 0px;
    background-image: url(../../assests/icons/navy-blue-check.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
    border: 2px solid #362460;
}


.support-text {
    font-size: 14px;
    color: #362460;
    font-weight: 600;
    margin-top: 30px;
}


.faq-list {}

.faq-list li:not(:last-child) {
    margin-bottom: 32px;
}

.faq-list li {}

.faq-list h3 {
    font-size: 18px;
    font-weight: 500;
    padding-left: 35px;
    position: relative;
    color: #001F3F;
    line-height: 30px;
}

.faq-list h3:before {
    position: absolute;
    color: #57a5fb;
    content: "\f059";
    left: 0px;
    font: normal normal normal 20px/1 FontAwesome;
    top: 7px;
}

.faq-list .faq-answer {
    border-left: 2px solid #C0DEFF;
    padding-left: 35px;
}

.faq-list .faq-answer p:last-child {
    margin-bottom: 0px;
}

.faq-answer p {
    color: #475668;
}

.home-faq h2 {
    font-size: 32px;
    color: #57A5FB;
    font-weight: 600;
    margin-bottom: 35px;
}

.book-inner {
    padding: 0px 50px;
    border-radius: 20px;
}

.book-inner h2 {
    font-size: 32px;
    color: #57A5FB;
    font-weight: 600;
    margin-bottom: 15px;
}


.book-inner p {
    font-size: 18px;
    color: #475668;
    margin-bottom: 20px;
}

.website-footer {
    padding: 75px 0px;
}

.website-footer h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.footer-logo p {
    margin-bottom: 0px;
    margin-top: 12px;
}

.footer-nav {}

.footer-nav ul {}

.footer-nav ul li:not(:last-child) {
    margin-bottom: 7px;
}

.footer-nav ul li a {}

.footer-nav ul li a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.git ul li:not(:last-child) {
    margin-bottom: 7px;
}

.git ul li {
    position: relative;
    padding-left: 25px;
}

.git ul li .fa {
    position: absolute;
    left: 0px;
    top: 5px;
}

.footer-social {
    margin-top: 7px;
}

.footer-social a {
    margin-top: 7px;
    font-size: 18px;
    color: #111111;
    text-decoration: none;
}

.footer-social ul li:not(:last-child) {
    margin-right: 25px;
}

.git ul li a {
    color: #111111;
    text-decoration: none;
}

.git ul li a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.website-footer,
.website-footer a,
.website-footer p {
    color: #111111;
}

.website-footer a {
    text-decoration: none;
}

.footer-copyright {
    font-size: 14px;
}

.footer-copyright a {
    text-decoration: none;
    color: #111;
}


.footer-copyright a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.trust-loop .owl-item:not(.center) {
    opacity: 55%;
}


.c-form {
    margin-top: 15px;
}

.c-form .form-control {
    border: 0;
    border-bottom: 1px solid #1E1E1E;
    border-radius: 0px;
    padding-left: 0px;
    color: #1E1E1E;
}

.contact-page .c-form iframe::-webkit-scrollbar {
    width: 5px;
}

.contact-page .c-form iframe::-webkit-scrollbar-track {
    background-color: #fbfbfb;
}

.contact-page .c-form iframe::-webkit-scrollbar-thumb {
    background-color: #57a5fb;
    height: 50px !important;
}

.form-control:focus,
.form-select:focus {
    outline: none;
    border-color: #57A5FB;
    box-shadow: none;
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #1E1E1E !important;
}

::placeholder {
    color: #1E1E1E !important;
}

.btn-send {
    padding: 10px 15px !important;
    font-weight: 600 !important;
    color: #fff !important;
    font-size: 18px !important;
    display: block;
    border: 0px !important;
    width: 100%;
    border-radius: 5px !important;
    background: #57A5FB !important;
}

.inner-title {
    background: #57A5FB;
}

.inner-title h1 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
}

.features-section {}

.features-text h2 {
    font-size: 30px;
    font-weight: 600;
}

.features-text p {
    font-size: 18px;
    color: #2B3857;
}

.features-opt {
    background: #e8f3ff;
    ;
}

.features-col {
    padding: 30px;
    border-radius: 20px;
    border: 1px solid rgb(71 86 104 / 50%);
}

.features-col img {}

.features-col h2 {
    font-size: 30px;
    font-weight: 500;
    color: #1E1E1E;
    margin: 20px 0px 15px 0px;
}

.features-col h3 {
    font-size: 22px;
    font-weight: 400;
    color: #475668;
}

.features-col p {
    color: #475668;
}

.features-col a {
    font-weight: 600;
    color: #57A5FB;
    text-decoration: none;
}

.features-col a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.why-join h2 {
    font-size: 32px;
    font-weight: 700;
    color: #1E1E1E;
    margin-bottom: 10px;
}

.why-join h2+p {
    color: #1E1E1E;
    font-size: 18px;
}

.nav-toggle {
    background: transparent;
    width: 100%;
    padding: 0px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    max-width: 40px;
}

.page-header .container {
    position: relative;
}


.click-slide {}

.click-slide {}

.click-slide li:not(:last-child) {
    margin-bottom: 10px;
}

.click-slide li a {
    display: block;
    padding: 25px 25px;
    border: 2px solid transparent;
    text-decoration: none;
}

.click-slide li.active a {
    border: 2px solid #57A5FB;
}

.click-slide li:not(.active) {
    opacity: 50%;
}

.click-slide li a:hover {}

.click-slide li a h3 {
    font-size: 22px;
    color: #001F3F;
    font-weight: 700;
}

.click-slide li p {
    margin-bottom: 0px;
    color: #001F3F;
}

.subscription-plans {
    background: rgb(87 165 251 / 10%);
}


.subscription-plans h2 {
    font-size: 32px;
    font-weight: 700;
    color: #1E1E1E;
    margin-bottom: 10px;
}

.subscription-plans p {
    color: #1E1E1E;
    font-size: 18px;
}

.features-section .accordion-button {
    background: rgb(87 165 251 / 15%) !important;
    border-radius: 10px !important;
    outline: none !important;
    box-shadow: none !important;
    color: #181F38 !important;
    font-size: 18px;
}

.features-section .accordion-collapse {
    background: rgb(87 165 251 / 15%) !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /*   border-bottom: 4px solid red;
    border-left: 2px solid red;
    border-right: 2px solid red;*/
}


.features-section .accordion-item {
    border: 0px !important;
}

.features-section .accordion .accordion-item:not(:last-child) {
    /* margin-bottom: 5px; */
}

.features-section .accordion-button:not(.collapsed) {
    /* border-left: 2px solid transparent;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;*/
}


.features-section .accordion-button:not(.collapsed) {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    /*    border-left: 2px solid red;
    border-top: 2px solid red;
    border-right: 2px solid red;*/
}

.subscription-section {
    background: #eef6ff;
    padding: 45px 0px;
}

.subscription-head {
    text-align: center;
    margin-bottom: 35px;
}

.subscription-head h2 {
    font-size: 22px;
    font-weight: 600;
    color: #001f3f;
    margin-bottom: 10px;
}

.subscription-head p {
    font-size: 18px;
    color: #2B3857;
    margin-bottom: 0px;
}

.price-box {
    background: #001f3f;
    border-radius: 8px;
    padding: 25px;
    text-align: center;
    
}


.sff-btn {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background: #5EA6F8;
    display: inline-block;
    text-decoration: none;
    padding: 8px 25px;
    border: 0px;
    border-radius: 8px;
}

.price-box h3 {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    margin: 10px 0px 15px 0px;
    line-height: 38px;
}

.price-box h4 {
    color: #57A5FB;
    font-size: 55px;
    font-weight: 600;
    margin-bottom: 15px;
}

.price-box h4 sup {
    font-size: 30px;
    font-weight: 500;
    left: 10px;
    top: -25px;
}

.customize-opt {
    margin-bottom: 30px;
}

.customize-opt ul {
    display: inline-block;
}

.customize-opt ul li {
    display: flex;
    align-items: center;
}

.customize-opt ul li:not(:last-child) {
    margin-bottom: 10px;
}

.customize-label {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    order: 1;
    margin-left: 20px;
}

.customize-num select {
    border-radius: 8px;
    border: 1px solid #0076F8;
    padding: 6px 10px;
    width: 100px;
    outline: none;
    box-shadow: none;
}

.customize-label:before {
    content: "";
    width: 7px;
    height: 7px;
    margin-right: 10px;
    background: #fff;
    display: inline-block;
    border-radius: 20px;
}

.exploring-list .exploring-block:not(:last-child) {
    margin-bottom: 50px;
}

.exploring-block h4 {
    font-size: 22px;
    color: #1E1E1E;
    font-weight: 500;
    margin: 10px 0px 5px 0px;
}

.exploring-block p {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #777777;
}


@media (min-width: 992px) {

    .main-menu ul li:not(:last-child) {
        margin-right: 45px;
    }
}

@media (max-width: 1399.98px) {
    .section-tabs .nav-pills li button {
        font-size: 16px;
        padding: 5px 10px !important;
    }
}

@media (max-width: 991.98px) {
    .for-mobi {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .main-menu {
        display: none;
        /* Initially hidden */
    }

    .main-menu.open {
        display: block;
        /* Display when toggled */
    }

    .main-menu {
        position: absolute;
        top: calc(100% - -20px);
        z-index: 99;
        background: #fff;
        right: 0px;
        width: 100%;
        padding: 5px 0px;
        display: none;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
    }

    .main-menu ul li a {
        padding: 10px 10px;
    }

    .exploring-list .exploring-block:not(:last-child) {
        margin-bottom: 24px;
    }
}

@media (max-width: 767.98px) {
    .book-inner {
        padding: 25px 15px 0px 15px;
    }

    .faq-list h3,
    .faq-list .faq-answer {
        padding-left: 25px;
    }

    .play-btn img {
        width: 50px;
    }

    .price-box {
        padding: 20px 15px;
    }

    .section-tabs .nav-pills li {
        width: 100%;
    }

    .section-tabs .nav-pills li:not(:last-child) {
        margin-bottom: 10px;
    }

    .section-tabs .nav-pills li button {
        width: 100%;
        text-align: left;
        padding: 5px 0px !important;
    }

    .project-platform .row p {
        font-size: 18px;
    }

    .section-tabs .tab-content h2 {
        font-size: 30px;
    }

    .section-tabs .tab-content p {
        font-size: 16px;
    }

    .see-all a {
        font-size: 18px;
    }

    .hero-banner {
        padding: 75px 0px;
    }

    .banner-caption h2 {
        line-height: 40px;
        font-size: 30px;
        margin-bottom: 15px;
    }

    .banner-caption h3 {
        margin-bottom: 10px;
    }

    .banner-caption p {
        font-size: 18px;
    }

    .banner-caption a {
        font-size: 18px;
    }
    .price-box-css{
        padding: 20px 15px;
    }
}

@media (max-width: 575.98px) {
    .customize-num select {
        padding: 6px 5px;
        width: 80px;
    }

    .customize-label {
        font-size: 18px;
        margin-left: 15px;
    }
}

.slick-slider .button {
    /* width: 299.429px; */
    /* margin-right: 20px; */
    display: none;
}

.slick-initialized .slick-slide {
    margin-right: 10px;
    width: 294.286px;
}

.trust-loop .slick-cloned .trust-text {
    background: #BDDCFF !important;
}

.trust-loop .slick-active .trust-text {
    background: #BDDCFF !important;
}

.trust-loop .slick-current .trust-text {
    background: #5EA6F8 !important;
}

.trust-loop .slick-current .trust-text p,
.trust-loop .slick-current .trust-text h4,
.trust-loop .slick-current .trust-text h5 {
    color: #fff;
}

.trust-loop .slick-dots {
    bottom: -50px;
}

.trust-loop .slick-dots li button::before {
    font-size: 11px !important;
}