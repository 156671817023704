.welcome-box {
  border-radius: 20px;
  background: #fff;
  padding: 20px;
  height: 324px;
  overflow-y: auto;
}

.profile-info h2 {
  font-size: 18px;
  font-weight: 600;
}

.profile-info h3 {
  font-size: 16px;
  font-weight: 500;
}

.welcome-box p {
  letter-spacing: 0.16px;
  line-height: 25px;
  text-align: justify;
  font-size: 16px;
}

.all-progress {
  border-radius: 4px;
  background: #57A5FB;
  padding: 8px 14px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  border: 0px;
}

.all-progress img {
  margin-right: 10px;
}

.edit-welcome,
.drag-me {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

.welcome-top button + button {
  margin-left: 15px;
}

.profile-img img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: fill;
}

.url-input {
  position: relative;
}
.url-input input,
.progress-txt textarea {
  background: #f0f0f0 !important;
  padding: 10px !important;
  font-size: 12px !important;
  color: #565656 !important;
  border: 0px !important;
  font-weight: 400 !important;
}
.url-input button {
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.progree-btn button {
  width: 20px;
}
.progree-btn button + button {
  margin-left: 5px;
}
.accordion-header {
  padding: 10px 0px;
}
.accordion-header h3 {
  margin-bottom: 0px;
  font-size: 16px;
}

.accordion-button::after {
  background-image: url("../../assests/images/down-angle.svg");
  height: 12px;
  background-size: auto;
  background-position: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../assests/images/down-angle.svg");
}

.accordion-body button {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
  }

  .progree-btn button {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
  }

  .edit-icon > img {
    width: 15px;
  }

@media screen and (max-width: 760px) {
  .welcome-box {
    width: -webkit-fill-available;
    margin-bottom: 1.5rem;
  }

  .accordion-body button {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
  }

  .progree-btn button {
    background: transparent;
    padding: 0px;
    outline: none;
    border: 0px;
  }

  .edit-icon > img {
    width: 15px;
  }

  .drag-me {
    display: none;
  }

  .welcome-top > .profile-area > .profile-img > img {
    width: 50px;
    height: 50px;
  }

  .all-progress {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100px;
    font-size: 8px;
  }

  .all-progress > img {
    width: 12px;
  }

  .all-progress > span {
    width: max-content;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    /* background: pink; */
  }

  .profile-info > h2 {
    font-size: 13px;
  }

  .profile-info > h3 {
    font-size: 12px;
  }

  .profile-area {
    display: flex;
    justify-content: center;
    /* background: green; */
  }

  .edit-welcome > img {
    width: 1.2rem;
  }

  .welcome-box > p {
    font-size: 12px;
    line-height: 1.5rem;
  }
}

.welcome-box::-webkit-scrollbar {
  width: 5px;
}

.welcome-box::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.welcome-box::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: 50px !important;
}