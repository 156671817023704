.working-inner {
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
  /* border: 5px solid #57A5FB; */
  border-top: 5px solid #57a5fb;
  border-right: 5px solid #57a5fb;
  border-bottom: 5px solid #57a5fb;
  border-left: 5px solid #57a5fb;
  background: linear-gradient(
      0deg,
      rgba(0, 118, 248, 0.12) 0%,
      rgba(0, 118, 248, 0.12) 100%
    ),
    #fff;
}

.current-working-task-section {
  width: -webkit-fill-available;
}

.working-inner-viewmore {
  padding: 20px;
  border-radius: 20px 20px 20px 20px;
  /* border: 5px solid #57A5FB; */
  border-top: 5px solid #57a5fb;
  border-right: 5px solid #57a5fb;
  border-bottom: 5px solid #57a5fb;
  border-left: 5px solid #57a5fb;
  background: linear-gradient(
      0deg,
      rgba(0, 118, 248, 0.12) 0%,
      rgba(0, 118, 248, 0.12) 100%
    ),
    #fff;
}

.current-search-bar {
  /* border-bottom: 1px solid #fff; */
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.viewline {
  border-bottom: 1px solid #fff;
}

.current-tasks-data {
  height: 200px;
  overflow-y: auto;
}
.current-tasks-data::-webkit-scrollbar {
  width: 5px;
}

.current-tasks-data::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.current-tasks-data::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}
.current-tasks-data-viewmore {
  height: 320px;
  overflow-y: auto;
}
.current-tasks-data-viewmore::-webkit-scrollbar {
  width: 5px;
}

.current-tasks-data-viewmore::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.current-tasks-data-viewmore::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.working-task h2 {
  font-size: 20px;
  font-weight: 600;
  color: #362460;
}

.working-task span {
  font-size: 14px;
  font-weight: 400;
}

.current-search-bar form {
  position: relative;
  width: 100%;
}

.current-search-bar form input {
  border-color: #cecece;
  color: #1e1e1e;
}

.current-search-bar form button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 0;
  right: 0;
}

.search-group .filter-icon {
  margin-left: 15px;
}

.current-tasks-label h3 {
  font-size: 16px;
  color: #57a5fb;
  font-weight: 600;
  margin-bottom: 0px;
}

.current-tasks-data p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}
.current-tasks-data-viewmore p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 400;
}

.edit-task-action {
  border: 0px;
  padding: 0px;
  background: transparent;
}

.task-btn button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
  margin-left: 15px;
}

.task-btn button + button {
  margin-left: 15px;
}

.current-tasks-data label {
  font-size: 16px;
  color: #362460;
  font-weight: 800;
  display: block;
}

.current-tasks-data .row:not(:last-child) {
  margin-bottom: 5px;
}

.task-progress-label {
  display: inline-block !important;
}

.assignee-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.assignee-label span {
  border-radius: 20px;
  width: 32px;
  color: #fff;
  height: 32px;
  background: #959595;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assignee-label span:not(:last-child) {
  margin-right: 10px;
}

/* @media (min-width: 992px) {
    .current-tasks-data label {
        display: none !important;
    }
} */

/* @media (max-width: 991.98px) {
    .current-tasks-data .row>* {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
} */

.current-task-time-track {
  display: inline-block;
  width: 100px;
  text-align: left;
}

.search-group button {
  background: transparent;
  padding: 0px;
  outline: none;
  border: 0px;
}

@media screen and (max-width: 760px) {
  .current-working-task h2 {
    font-size: 15px;
  }

  .current-tasks-data > div:not(:last-child) {
    /* background: #eff6ff;
    border-radius: 20px;
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-top: 4px solid #57a5fb;
    border-right: 2px solid #57a5fb;
    border-left: 2px solid #57a5fb;
    border-bottom: 2px solid #57a5fb; */
    border-bottom: 1px solid #7B838A;
  }

  .task-row > div:last-child {
    margin-bottom: 15px;
  }

  .current-tasks-data-viewmore > div:not(:last-child) {
    border-bottom: 1px solid #7B838A;
  }

  .current-tasks-data .row {
    margin-right: 15px;
  } 

  .current-tasks-data-viewmore .row {
    margin-right: 15px;
  } 

  .task-row div > p {
    font-size: 15px;
  }

  
  .task-row > div:nth-child(-n+6) {
    background-color: #fff;
    border-radius: 10px;
    margin: 5px 0;
    padding: 0 5px 0 10px;
    height: auto;
  }

  .task-row > div:nth-child(1) > p {
    /* font-size: 20px; */
    font-weight: 600;
  }

  .edit-task-action > img {
    width: 20px;
  }

  .edit-task-action > img {
    width: 15px;
    margin-left: 10px;
  }

  .task-row > div:last-child {
    background: white;
    color: black;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 80px;  
    height: 30px;
    border-bottom: 2px solid #000;
    margin-left: 2px;
    margin-top: 10px;
  } 

  .task-row > div:last-child::after {
    content: 'Edit';
  }

  .task-row > div:nth-child(1)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 90px;
    color: #7B838A;
  }

  .task-row > div:nth-child(1) {
    display: flex;
  }

  .task-row > div:nth-child(2)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 75px;
    color: #7B838A;
  }

  .task-row > div:nth-child(2) {
    display: flex;
  }

  .task-row > div:nth-child(3)::before {
    content: 'Task Assigned: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 114px;
    color: #7B838A;
  }

  .task-row > div:nth-child(3) {
    display: flex;
  }

  .task-row > div:nth-child(4)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 60px;
    color: #7B838A;
  }

  .task-row > div:nth-child(4) {
    display: flex;
  }

  .task-row > div:nth-child(5)::before {
    content: 'Assignee: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 78px;
    color: #7B838A;
  }

  .task-row > div:nth-child(5) {
    display: flex;
  }

  .task-row > div:nth-child(6)::before {
    content: 'Time Tracking: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 109px;
    color: #7B838A;
  }

  .task-row > div:nth-child(6) {
    display: flex;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(1)::before {
    content: 'Task Name: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 87px;
    color: #7B838A;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(1) {
    display: flex;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(2)::before {
    content: 'Due Date: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 73px;
    color: #7B838A;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(2) {
    display: flex;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(3)::before {
    content: 'Task Assigned: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 112px;
    color: #7B838A;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(3) {
    display: flex;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(4)::before {
    content: 'Project: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 60px;
    color: #7B838A;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(4) {
    display: flex;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(5)::before {
    content: 'Assignee: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 75px;
    color: #7B838A;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(5) {
    display: flex;
  }

  .current-tasks-data-viewmore .task-row > div:nth-child(6)::before {
    content: 'Time Tracking: ';
    font-weight: 600;
    font-size: 15px;
    min-width: 105px;
    color: #7B838A;
  } 

  .current-tasks-data-viewmore .task-row > div:nth-child(6) {
    display: flex;
  }

}


@media screen and (min-width: 768px) and (max-width: 1280px) {
    .current-tasks-label > div {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 1.2fr 0.9fr 0.9fr;
    }

    .current-tasks-data > div {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 1.2fr 0.9fr 0.9fr;
    }
}