body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 8px; 
}

body::-webkit-scrollbar-track {
  background-color: #fbfbfb; 
}

body::-webkit-scrollbar-thumb {
  background-color: #57A5FB; 
  height: 40%; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* overflow-x: hidden; */
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .mobile-container {
    display: flex;
    flex-wrap: wrap;
  }

  .mobile-container > div {
    width: -webkit-fill-available;
  }

  .chat-and-tickets {
    width: -webkit-fill-available;
  }

  .welcome-box {
    margin-bottom: 1.5rem;
  }

  .upcoming-appointments {
    margin-bottom: 1.5rem;
  }

  .assigned-tasks-mobile {
    margin-bottom: 1.5rem;
  }

  .waiting-approval-mobile {
    margin-bottom: 1.5rem;
  }
}
