.waiting-approval {
  border-radius: 20px 20px 0px 0px;
  background: #dbe7ff;
  padding: 20px;
  /*    height: 100%;*/
  border-top: 8px solid #57a5fb;
  border-right: 2px solid #57a5fb;
  border-bottom: 2px solid #57a5fb;
  border-left: 2px solid #57a5fb;
}

.waiting-approval-viewmore {
  border-radius: 20px 20px 20px 20px;
  background: #dbe7ff;
  padding: 20px;
  /*    height: 100%;*/
  border-top: 8px solid #57a5fb;
  border-right: 2px solid #57a5fb;
  border-bottom: 2px solid #57a5fb;
  border-left: 2px solid #57a5fb;
}

.waiting-approval h2 {
  font-size: 20px;
  font-weight: 600;
}

.approval-info-col {
  height: 335px;
  overflow-y: auto;
  overflow-x: hidden;
}
.approval-info-col::-webkit-scrollbar {
  width: 10px;
}

.approval-info-col::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.approval-info-col::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.approval-info-col-viewmore {
  height: 415px;
  overflow-y: auto;
  overflow-x: hidden;
}
.approval-info-col-viewmore::-webkit-scrollbar {
  width: 10px;
}

.approval-info-col-viewmore::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

.approval-info-col-viewmore::-webkit-scrollbar-thumb {
  background-color: #57a5fb;
  height: auto;
}

.approval-info-col-viewmore .col-md-3 {
  width: 30%;
}

.approval-heading {
  /* border-bottom: 1px solid #A899CC; */
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.viewmore-line {
  border-bottom: 1px solid #a899cc;
}

.project-img {
  width: 100% !important;
  object-fit: cover;
  height: 190px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.approval-info {
  font-size: 16px;
  font-weight: 400;
}

.approval-info ul li label {
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
}

.approval-heading button + button {
  margin-left: 15px;
}

.approval-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.approval-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.approval-done {
  border-radius: 5px;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  background: transparent;
  color: #57a5fb;
  border: 2px solid #57a5fb;
}

.approval-reject {
  border-radius: 5px;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  background: transparent;
  color: #de2c21;
  border: 1px solid #de2c21;
}
.approval-approved {
  border-radius: 5px;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  color: #fff;
  background: transparent;
  background: #3ebb11;
  border: 1px solid #3ebb11;
}

.status-label span {
  color: #f13a3a;
  font-size: 14px;
}

.approval-view {
  border-radius: 5px;
  background: #57a5fb;
  padding: 8px 14px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  border: 0px;
  margin-right: 5px;
}

.approval-btn button img {
  margin-right: 10px;
}

.approval-modal .modal-content {
  background: #d7d3df !important;
  padding: 0px 20px 20px 20px;
}

.approval-modal .modal-body {
  padding: 20px;
  border-radius: 10px;
}

.approval-modal .modal-header {
  border: 0px;
}

.mark-reject {
  border-radius: 5px;
  border: 1px solid #de2c21;
  padding: 4px 22px;
  background: transparent;
  font-weight: 500;
  color: #de2c21;
  outline: none;
  box-shadow: none;
}

.mark-approve {
  border-radius: 5px;
  border: 0px;
  padding: 4px 22px;
  border-radius: 5px;
  background: #3ebb11;
  border: 1px solid #3ebb11;
  font-weight: 500;
  color: #fff;
  outline: none;
  box-shadow: none;
}
.show-details {
  border-radius: 5px;
  border: 0px;
  padding: 4px 22px;
  border-radius: 5px;
  background: #7D9CFF;
  border: 1px solid #7D9CFF;
  font-weight: 500;
  color: #fff;
  outline: none;
  box-shadow: none;
}

.approval-slides img {
  border-radius: 10px;
}

.approval-slides .carousel-indicators button {
  background: #d9d9d9 !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 8px !important;
  border: 0px !important;
  opacity: 1;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.08);
}

.approval-slides .carousel-indicators .active {
  background: #424242 !important;
  width: 16px !important;
}

.approval-popup-info {
  padding-top: 20px;
}

.approval-popup-info ul li span {
  font-size: 12px;
  color: #878787;
  font-weight: 400;
  margin-right: 5px;
}

.approval-popup-info ul li {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.waiting-approval-mobile {
  width: -webkit-fill-available;
}

.approval-heading button {
   background: transparent;
   padding: 0px;
   outline: none;
   border: 0px;
}

@media (min-width: 768px) {
  .approval-popup-info ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } 
}

@media (max-width: 767.98px) {
  .approval-popup-info ul li:not(:last-child) {
    margin-bottom: 5px;
  }
  /* .approval-info ul li label {
    font-weight: 400;
    font-size: 16px;
    display: inline-block;
  } */
  
}

.details-viewmore {
  z-index: 99999;
}

@media screen and (min-width: 760px) and (max-width: 1024px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: 33%;
}
  
}

@media screen and (max-width: 760px) {

  .waiting-approval {
    border-radius: 20px 20px 20px 20px;
  }

  .waiting-approval-mobile {
    margin-bottom: 1.5rem;
  }

  .waiting-approval h2 {
    font-size: 15px;
  }

  .no-default > img {
    width: 30px;
  }

  .approval-info-col {
    padding: 0 12px;
  } 
  .modal-header .close-btn {
    width: 15px;

  }
  .approval-modal-btn {
    margin-left: 0px !important;
  }
  .mark-reject {
    padding: 0 6px;
    margin-left: -10px;
  }
  .mark-approve {
    padding: 0 6px;
    margin-left: -10px;
  }
  .show-details {
    padding: 0 6px;
    margin-left: -10px;
  }
  /* .approval-info-col-viewmore .col-md-3 {
    width: 43%;
  } */
  .mobile-view{
    flex-direction: column !important;
  }
}

@media screen and (max-width: 575.98px) {
  .waiting-approval {
    padding: 10px;
  }
}
