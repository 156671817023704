.bg-image {
    background: #f6f7f9 url('../../assests/images/bg-image.jpg') center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
}

.bg-box {
    width: 100%;
}

.enter-opt {
    width: calc(100% - 30px);
    max-width: 500px;
    margin: auto;
    height: auto;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.90);
    border-radius: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px;
}

.enter-opt h1 {
    color: #1E1E1E;
    font-size: 35px;
    font-weight: 500;
}

.enter-opt p {
    font-size: 21px;
    margin-bottom: 0px;
}

.enter-opt p strong {
    font-weight: 700;
    color: #362460;
}

.opt-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.opt-col input:not(:last-child) {
    margin-right: 20px;
}

.opt-col input {
    border-radius: 10px;
    width: 36px;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    color: #1E1E1E;
    text-align: center;
    padding: 0px;
    border-color: #1E1E1E;
}

.submit-btn {
    border-radius: 10px;
    background: #362460;
    box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
    padding: 12px 60px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    border: 0px;
    outline: none;
}